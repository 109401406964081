// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { COLUMN } from '@/tools/histogram/aggregationBin.actions';
import { sqFormulasApi, sqItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqTrendTableStore, sqWorkbenchStore, sqWorksheetStore } from '@/core/core.stores';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { isHidden } from '@/utilities/trendChartItemsHelper.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import { catchItemDataFailure, fetchItemProps, getChartWidth, setTrendItemProps } from '@/trendData/trend.actions';
import { runTable } from '@/utilities/formula.utilities';
import { getMaxSeriesPixels } from '@/core/utilities';

/**
 * This function adds a table to the specified panel. Currently only TREND_PANELS.SERIES is a valid option.
 * To make future enhancements easier the param is included.
 * If an item already exists in the panel then its name is updated to reflect changes.
 *
 * @param {Object} item - the table to be added.
 * @param {Object} [props] - additional properties to be set on the item before fetching
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 *
 * @returns {Promise} that resolves when the data for the table is available
 */
export function addTable(item, props?, option?) {
  if (!sqTrendTableStore.findItem(item.id)) {
    const payload = {
      id: item.id,
      name: item.name,
      color: item.color,
    };

    flux.dispatch('TREND_ADD_TABLE', payload, option);
  }

  setTrendItemProps(item.id, props, option);

  return Promise.all([fetchItemProps(item.id), fetchTableData(item.id)]);
}

/**
 * Fetches the specified table. If the worksheet is displayed in any view other than calendar time, histograms won't
 * be displayed, and we won't request data in those instances. This function prepares the return data for display,
 * and creates the data array that will be used for the x-axis label display. In addition, this function ensures
 * that no empty buckets are shown if the option to show empty buckets is turned off.
 *
 * @param {String} id - the id of the table to fetch
 *
 * @returns {Promise} Resolves when table data and item properties have been fetched.
 */
export function fetchTableData(id: string) {
  const cancellationGroup = `fetchTableData${id}`;
  const item = sqTrendTableStore.findItem(id);
  if (!item) {
    return Promise.resolve();
  }

  const fftTable = item.calculationType === TREND_TOOLS.FFT_TABLE || item.itemType === ITEM_TYPES.TABLE;
  const numPixels = Math.min(getChartWidth(), getMaxSeriesPixels());

  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND || (fftTable && !numPixels)) {
    return Promise.resolve();
  }

  if (isHidden({ item: sqTrendTableStore.findItem(id) })) {
    flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id }, PUSH_IGNORE);
    return Promise.resolve();
  }

  const args = { fragments: { numPixels } };

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);

  const itemPromise = sqFormulasApi.getFunction({ id }).then(({ data }) => data);
  const tablePromise = cancelGroup(cancellationGroup)
    .then(() => runTable(id, cancellationGroup, args))
    .then((result) => {
      flux.dispatch(
        'TREND_SET_DATA_STATUS_PRESENT',
        _.assign({ id }, _.pick(result, ['warningCount', 'warningLogs', 'timingInformation', 'meterInformation'])),
        PUSH_IGNORE,
      );

      return result.table;
    })
    .catch(_.partial(catchItemDataFailure, id, cancellationGroup));
  const uiConfigPromise = sqItemsApi
    .getProperty({ id, propertyName: SeeqNames.Properties.UIConfig })
    .then(({ data }) => data?.value);

  return Promise.all([itemPromise, tablePromise, uiConfigPromise]).then(([item, table, uiConfigString]) => {
    const uiConfig = JSON.parse(uiConfigString);
    const includeEmptyBuckets = _.get(uiConfig, 'includeEmptyBuckets', true);
    const signalToAggregateName = _.find(item.parameters, ['name', 'signalToAggregate']).item.name;
    const labelCategories = [];
    const data = [];
    let i = 0;
    const allSeriesData = [];
    const payload = { id };

    if (_.get(table.headers, 'length') > 2) {
      // this path is taken if the table is the result of a sub-grouped aggregation.
      const secondaryBucket = _.chain(table.data)
        .map((row) => row[1])
        .uniq()
        .value();

      const headerLabelFunction = getLabelFunction(table.headers[1]);
      const labelFunction = getLabelFunction(table.headers[0]);

      _.forEach(secondaryBucket, (bucket) => {
        const seriesName = headerLabelFunction(bucket);
        const seriesData = { data: [], name: seriesName };

        for (i = 0; i < table.data.length; i++) {
          const entry = table.data[i];
          if (entry[1] === bucket) {
            seriesData.data.push(entry[2]);
          }

          labelCategories.push(labelFunction(entry[0]));
        }

        allSeriesData.push(seriesData);
      });

      _.assign(payload, { tableData: allSeriesData }, { categories: _.uniq(labelCategories) });
    } else {
      const labelFunction = getLabelFunction(table.headers[0]);
      for (i = 0; i < table.data.length; i++) {
        const entry = table.data[i];

        if (includeEmptyBuckets || (entry[1] !== null && entry[1] !== 0)) {
          labelCategories.push(labelFunction(entry[0]));
          data.push(entry[1]);
        }
      }

      _.assign(payload, {
        tableData: [
          {
            name: table.id,
            data,
          },
        ],
        categories: labelCategories,
      });
    }

    flux.dispatch('TREND_TABLE_RESULTS_SUCCESS', _.assign(payload, { signalName: signalToAggregateName }), PUSH_IGNORE);

    if (uiConfig.type === TREND_TOOLS.FFT_TABLE) {
      flux.dispatch(
        'TREND_TABLE_SET_OUTPUT_UNITS',
        _.assign(payload, { outputUnits: uiConfig.outputUnits }),
        PUSH_IGNORE,
      );
    }

    return payload;
  });
}

/**
 * Updates the bin color. A bin is represented by a "series" in the highcharts chart.
 *
 * @param {String} id - the name of the series (aka the label)
 * @param {String} bin - the identifier of the bin
 * @param {String} color - color to assign to the bin
 */
export function updateBinColor(id: string, bin: string, color: string) {
  flux.dispatch('TREND_TABLE_SET_BIN_PROPERTIES', {
    id,
    bins: _.set({}, bin, { color }),
  });
}

/**
 * Updates the bin visibility of an item in the table.
 *
 * @param {String} id - the name of the series
 * @param {String} bin - the identifier of the bin
 * @param {Boolean} hidden - true if the bin is hidden
 */
export function updateBinVisibility(id: string, bin: string, hidden: boolean) {
  flux.dispatch('TREND_TABLE_SET_BIN_PROPERTIES', {
    id,
    bins: _.set({}, bin, { hidden }),
  });
}

/**
 * Resets all properties on bins to defaults (color and visibility)
 *
 * @param {String} id - the name of the series
 */
export function resetBins(id: string) {
  flux.dispatch('TREND_TABLE_RESET_BINS', { id });
}

/**
 * This function is responsible for fetching all tables currently shown in the details panel.
 *
 * @returns {Promise} that resolves when data for all tables has been received.
 */
export function fetchAllTables() {
  return _.chain(sqTrendTableStore.tables)
    .reject((item) => isItemRedacted(item))
    .map('id')
    .map(_.ary(fetchTableData, 1))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * This function is responsible for fetching all fft tables currently shown in the details panel.
 *
 * @returns {Promise} that resolves when data for all tables has been received.
 */
export function fetchAllFftTables(): Promise<any> {
  return _.chain(sqTrendTableStore.tables)
    .filter(['calculationType', TREND_TOOLS.FFT_TABLE])
    .map('id')
    .map(_.ary(fetchTableData, 1))
    .thru((p) => Promise.all(p))
    .value();
}

type LabelFunction = (value: string) => number | string;

/**
 * Returns a function that knows how to properly format the value in the corresponding table column.
 * Y-value based aggregations always return the binStart,binEnd, zero padded - so we need to remove leading zeros
 * and replace the ',' with a '-'.
 *
 * Time based aggregations need to be formatted (mostly by moment) to turn into useful data.
 *
 * Condition properties are shown as is, unless the values are numbers, then they are truncated.
 *
 * @param {Object} tableHeader - an object the defining the table header
 * @param {Object} tableHeader.name - the name of the column. The name is used to determine how to format the
 *   value.
 *
 * @returns {Function} that renders the label as expected.
 */
export function getLabelFunction(tableHeader): LabelFunction {
  let labelFunction: LabelFunction;
  if (tableHeader.name.indexOf(COLUMN.TIME) > -1) {
    const key = tableHeader.name.substring(tableHeader.name.indexOf('_') + 1);
    switch (key) {
      case 'Hour':
        labelFunction = (hour) => parseInt(hour, 10) + 1;

        break;
      case 'Day Of Week':
        labelFunction = (day) => {
          const localMoment = moment();
          localMoment.locale(sqWorkbenchStore.userLanguage);

          return localMoment.localeData().weekdays()[+day % 7];
        };

        break;
      case 'Week':
        labelFunction = (week) => week;

        break;
      case 'Month':
        labelFunction = (month) =>
          moment()
            .month(+month - 1)
            .format('MMM');

        break;
      case 'Quarter':
        labelFunction = (quarter) => `Q${quarter}`;

        break;
      case 'Day Of Year':
        labelFunction = (day) => moment(day, 'DDD').format('DDD');

        break;
      default:
        labelFunction = (value) => value;
    }
  } else if (tableHeader.name.indexOf(COLUMN.Y_VALUE) > -1) {
    labelFunction = (range) => {
      const idx = _.indexOf(range, ',');
      // chop off leading padded zeros
      const lower = range.substring(0, idx).replace(/^0+(?=[0-9]+)/, '');
      const upper = range.substring(idx + 1).replace(/^0+(?=[0-9]+)/, '');

      return `${getFormattedNumberOrString(lower)}-${getFormattedNumberOrString(upper)}`;
    };
  } else {
    labelFunction = getFormattedNumberOrString;
  }

  /**
   * Helper function that returns a rounded number, if value is a number, or the unmodified String
   * if value is not a Number;
   *
   * @param {String| Number} value - either a String or a Number
   *
   * @returns {String} formatted number or unmodified String
   */
  function getFormattedNumberOrString(value: string | number): string | number {
    const numberValue = _.toNumber(value);
    if (_.isFinite(numberValue)) {
      return formatNumber(numberValue, { format: 'decp:4' });
    }

    return value;
  }

  return labelFunction;
}
